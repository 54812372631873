
























































































































.content-item {
  border-radius: 20px;
}

.left-content {
  margin-right: 20px;
  width: 40%;
  background: #0C0E3F;
  display: flex;
  flex-direction: column;
}

.top-title {
  margin-top: 42px;
  text-align: center;

  img {
    width: 41px;
    height: 15px;
  }

  .top-text {
    margin: 0 21px;
    font-size: 24px;
    color: #FFFFFF;
  }
}

.script-content {
  margin: 51px 0 20px;
  flex: 1;
  display: flex;
  flex-direction: column;
  color: #fff;
  overflow: hidden;

  ::v-deep .el-scrollbar {
    flex: 1;
    height: 1%;

    .el-scrollbar__wrap {
      overflow-x: hidden;

      .el-scrollbar__view {
        height: 100%;
        margin-left: 70px;
      }
    }

    .el-scrollbar__bar.is-vertical {
      .el-scrollbar__thumb {
        width: 8px;
        background: #4B45FF;
      }
    }
  }

  .script-item {
    position: relative;
    cursor: grab;

    /*&::before {*/
    /*    position: absolute;*/
    /*    width: 1px;*/
    /*    height: 100%;*/
    /*    content: '';*/
    /*    left: 12px;*/
    /*    top: 26px;*/
    /*    background: #444797;*/
    /*}*/

    .title-content {
      display: flex;
      align-items: center;
      font-size: 18px;

      .title-left {
        width: 26px;
        height: 26px;
        background: #BFBFBF;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .title-right {
        margin-left: 12px;
      }
    }

    .active-title {

      .title-left {
        background: #F49D19;
      }
    }

    .children-content {
      margin-left: 12px;
      border-left: 1px dashed #444797;
      padding: 20px 0 20px 28px;

      .script-subItem {
        /*margin-top: 20px;*/
        width: 90%;
        word-break: break-all;
        line-height: 40px;
        opacity: 0.3;
        padding: 10px;
      }
    }
  }

  .script-title {
    margin-left: 60px;
    font-size: 18px;
  }

  .script-inner {
    width: calc(100% - 60px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    text-align: left;
    margin-left: 60px;

    ::v-deep .el-scrollbar__wrap {
      width: 100%;
      margin-right: -40px !important;
    }

    .inner-keywords {
      width: 100%;
    }

    .inner-main {
      width: 80%;
      margin-top: 30px;
    }
  }
}

.isCurrent {
  opacity: 1 !important;
  font-size: 16px;
  color: #4B45FF;
  background: rgba(10, 8, 73, 0.5);
}

.script-content {
  margin: 51px 0 20px;
  flex: 1;
  display: flex;
  flex-direction: column;
  color: #fff;
  overflow: hidden;

  ::v-deep .el-scrollbar {
    flex: 1;
    height: 1%;

    .el-scrollbar__wrap {
      overflow-x: hidden;

      .el-scrollbar__view {
        height: 100%;
        margin-left: 70px;
      }
    }

    .el-scrollbar__bar.is-vertical {
      .el-scrollbar__thumb {
        width: 8px;
        background: #4B45FF;
      }
    }
  }

  .script-item {
    position: relative;
    cursor: grab;

    /*&::before {*/
    /*    position: absolute;*/
    /*    width: 1px;*/
    /*    height: 100%;*/
    /*    content: '';*/
    /*    left: 12px;*/
    /*    top: 26px;*/
    /*    background: #444797;*/
    /*}*/

    .title-content {
      display: flex;
      align-items: center;
      font-size: 18px;

      .title-left {
        width: 26px;
        height: 26px;
        background: #BFBFBF;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .title-right {
        margin-left: 12px;
      }
    }

    .active-title {

      .title-left {
        background: #F49D19;
      }
    }

    .children-content {
      margin-left: 12px;
      border-left: 1px dashed #444797;
      padding: 20px 0 20px 28px;

      .script-subItem {
        /*margin-top: 20px;*/
        width: 90%;
        word-break: break-all;
        line-height: 40px;
        opacity: 0.3;
        padding: 10px;
        min-height: 60px;

        .subItem-time {
          position: absolute;
          left: -75px;
          text-align: right;
          width: 60px;
        }
      }
    }
  }
}

.color-word {
  color: #4B45FF;
}
